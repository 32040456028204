import Head from "next/head"
import Link from "next/link"
import { Box, Button, Heading } from "@chakra-ui/react"
import Logo from "@common/components/Logo"

const Index = () => (
	<>
		<Head>
			<title>Not Found</title>
		</Head>
		<Box
			borderRadius="xl"
			bg="theme_accent"
			textAlign="center"
			p={12}
			margin="auto"
			mt="15vh"
			maxW="40rem"
			// boxShadow="dark-d"
			// height={[650, 675, 700, 700, 725, 850]}
		>
			<Logo />

			<Heading size="lg" py="12">
				Page not found
			</Heading>

			<Link href="/" passHref>
				<Button fontSize="sm" variant="solid" mb="2">
					Back to home
				</Button>
			</Link>
		</Box>
	</>
)

export default Index
